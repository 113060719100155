import React, { useRef, useState } from "react";
import "./App.css";
import { EkoVideo } from "@ekolabs/eko-react-sdk";
import logo from './logo.png'

const DamanVideoId = 'VyY2Y0'
const VIDEO_ID = 'AJm9vy'

function App() {
  const [showControls, toggleControls] = useState(false)
  const [showLogo, toggleLogo] = useState(true)

  // let decision = player.decision.add("question", ["toBe", "notToBe"]);
  // console.log(decision);
  let playerEventHandlers = {
    play: () => toggleControls(false),
    pause: () => toggleControls(true),
    // nodestart: (node) => console.log(node),
  };

  const logRef = useRef(null)
  const logtimeRef = useRef(null)

  const ekoPlayer = useRef(null);
  const onPlayerInit = (player) => {
    ekoPlayer.current = player;
  };

  const handleEnter = (event) => {
    console.log("leftpp")

    toggleLogo(false)
    // if (logtimeRef.current) {
    //   clearInterval(logtimeRef.current)
    // }
    // logtimeRef.current = setTimeout(() => {
    //   toggleLogo(true)
    // }, 2000)
  }

  const handleLeave = (event) => {
    console.log("left")
    if (logtimeRef.current) {
      clearInterval(logtimeRef.current)
    }
    logtimeRef.current = setTimeout(() => {
      toggleLogo(true)
    }, 2000)
  }

  return (
    <div className="App">
      <div className="videoContainer">
        <EkoVideo
          id={VIDEO_ID}
          params={
            {
              autoplay: true,
            }
          }
          onPlayerInit={onPlayerInit}
          events={playerEventHandlers}
          loadingCover={() => {
            return (
              <div className="holder">
                <span className="loader">
                  <div class="lds-dual-ring"></div>
                </span>
              </div>
            )
          }}
        />
        {/* {
          <div className={`logoContianer ${showLogo ? 'show' : 'hide'}`} ref={logRef}
          // onMouseEnter={handleEnter}
          // onTouchStart={() => handleEnter()}
          // onTouchEnd={() => handleLeave()}
          // onMouseLeave={() => handleLeave()}
          >
            <img src={logo} alt="logo" />
          </div>
        } */}

        {
          showControls &&
          <div onClick={() => ekoPlayer.current.play()} className="holder">
            <div className="playButtonContainer">
              <svg viewBox="0 0 100 100"><circle cx="50" cy="50" r="46" stroke="#7420b9" stroke-width="3"></circle><circle fill="#111111" cx="50" cy="50" r="46"></circle><path fill="#7420b9" d="M70.8,49.7c0,1-1,1.8-1,1.8L41.6,70.1c-2,1.4-3.7,0.4-3.7-2.3V31.6c0-2.7,1.7-3.8,3.7-2.4l28.1,18.6C69.7,47.9,70.8,48.6,70.8,49.7z"></path></svg>
            </div>
          </div>
        }

      </div>
    </div>
  );
}

export default App;
